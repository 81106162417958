<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-2 d-flex">
      <button
        class="button-primary text-white d-flex mr-1 px-[5px]"
        @click="$router.back()"
      >
        <span
          class="k-arrow-left-2 h-100 font-bold text-10"
          style="line-height: 1.5;"
        />
      </button>
      <span class="text-10 text--dark">Invoice</span>
    </div>
    <ValidationObserver
      ref="formRules"
      v-slot="{ }"
    >
      <BRow>
        <BCol md="7">
          <!-- form -->
          <BRow>
            <BCol md="12">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Partner <span class="text-danger">*</span>
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Posisi"
                  rules="required"
                >
                  <VSelect
                    v-model="idPartner"
                    label="name"
                    :reduce="option => option.id"
                    :options="listPartners"
                    placeholder="Ketik untuk mencari..."
                    disabled
                    @search="(search) => search.length > 0 ? onSearch(search) : ''"
                    @input="handleInput"
                  />
                  <small class="text-danger">{{
                    errors[0]
                  }}</small>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
            <BCol md="12">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Periode <span class="text-danger">*</span>
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Periode"
                  rules="required"
                >
                  <MonthlyPicker
                    v-model="periode"
                    :month-labels="monthlabel"
                    :max="maxDatePicker"
                    date-format="MMM YYYY"
                    :disabled="true"
                    :clear-option="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
            <BCol md="12">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Tipe Pembayaran <span class="text-danger">*</span>
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Tipe Pembayaran"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="isEarlyPayment"
                    class="mt-50"
                    :options="paymentOptions"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
            <BCol md="12">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Keterangan
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Keterangan"
                  rules="max:140"
                >
                  <b-form-textarea
                    v-model="notes"
                    disabled
                  />
                  <div class="flex">
                    <small class="text-danger w-full">{{ errors[0] }}</small>
                    <small class="self-end">{{ notes.length }}/140</small>
                  </div>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
          </BRow>
        </BCol>
        <BCol
          md="5"
          class="d-flex justify-content-end align-items-end pb-1"
        >
          <BButton
            aria-label="download"
            :disabled="loadDownload || loading"
            variant="outline-primary"
            :class="{ 'k-document-download': !loadDownload }"
            class="!text-[20px] w-fit h-[38px] px-[32px] rounded-[8px] !grid place-items-center disabled:!border-[#C2C2C2] disabled:!text-[#C2C2C2] disabled:pointer-events-none"
            @click="onDownloadInvoice"
          >
            <template v-if="loadDownload">
              {{ percentDownload }}%
            </template>
          </BButton>
          <button
            class="button-primary ml-1 fw-bold px-[40px] py-[8px]"
            :disabled="listDisbursementId.length < 3"
            @click="disburseAll()"
          >
            Disburse Semua
          </button>
        </BCol>
      </BRow>
    </ValidationObserver>

    <BCol
      md="12"
      class="d-flex flex-column py-[20px] items-end border-t"
    >
      <div
        class="d-flex justify-content-end cursor-pointer gap-10 items-center"
        @click="visible = !visible"
      >
        <span class="text-8 text-danger">{{ visible ? 'Tutup' : 'Buka' }}</span>
        <span
          v-if="visible"
          class="k-arrow-up-2 h-100 font-bold text-10 text-danger"
        />
        <span
          v-else
          class="k-arrow-down-1 h-100 font-bold text-10 text-danger"
        />
      </div>

      <BCollapse
        v-model="visible"
        class="mt-[5px]"
      >
        <div class="d-flex flex-column items-end">
          <span class="text-8 text--dark font-bold">
            Total Biaya Admin: {{ IDR(isTax ? totalAdminFee - totalTax : totalAdminFee) }}
            <span v-if="isTax">{{ `( ${IDR(totalAdminFee)} - ${IDR(totalTax)} )` }}</span>
          </span>
          <template v-if="totalAdminFee > 0">
            <span class="text-8 text-[#828282] font-bold">PPN {{ ppn }}%: {{ IDR(ppnValue) }}</span>
            <span class="text-8 text--dark font-bold">Total Setelah PPN: {{ IDR((isTax ? totalAdminFee - totalTax : totalAdminFee) + (+ppnValue)) }}</span>
          </template>
          <span class="text-8 text--dark font-bold">Total Gaji Talent: {{ IDR(totalTalentSalary) }}</span>
          <span class="text-8 text--dark font-bold">Total Biaya Lainnya: {{ IDR(totalAdditionalCost) }}</span>
          <span class="text-8 text--dark font-bold">Xendit (Layanan Admin): {{ IDR(adminServicefee) }} </span>
        </div>
      </BCollapse>

      <span
        class="text-8 font-bold"
        style="color: #34A770;"
      >Total Nominal: {{ IDR(totalNominal) }}</span>
    </BCol>

    <div id="table">
      <BTable
        :items="items"
        :fields="fields"
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        show-empty
      >
        <template #cell(id)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(talent_name)="data">
          <div class="d-flex flex-column">
            <span>{{ data.item.talent_name }}</span>
            <span class="text-6 text-[#828282]">{{ data.item.role_name }}</span>
          </div>
        </template>
        <template #cell(disbursement_status)="data">
          <span
            v-if="data.item.disbursement_status.toUpperCase() === 'FAILED'"
            class="status status-red"
          >failed</span>
          <span
            v-if="data.item.disbursement_status.toUpperCase() === 'PENDING'"
            class="status status-yellow"
          >disbursement waiting</span>
          <span
            v-if="data.item.disbursement_status.toUpperCase() === 'COMPLETED'"
            class="status status-green"
          >success</span>
          <span
            v-if="data.item.disbursement_status.toUpperCase() === 'CREATED'"
            class="status status-green"
          >created</span>
        </template>
        <template #cell(talent_salary)="data">
          <span>{{ IDR(getTalentSalary(data.item.talent_id)) }}</span>
        </template>
        <template #cell(action)="data">
          <BButton
            v-if="data.item.disbursement_status.toUpperCase() === 'FAILED' && data.item.disbursement_status.toUpperCase() === 'CREATED'"
            size="sm"
            variant="primary"
            :disabled="loadingSubmit"
            @click="sendDisbursement(data.item.disbursement_id)"
          >
            Disburse
          </BButton>
        </template>
      </BTable>
    </div>
  </BCard>
</template>

<script>
import VSelect from 'vue-select'
import MonthlyPicker from 'vue-monthly-picker'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { komtimAxiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'
import { alertError, alertSuccess } from '@toast'
import { required } from '@validations'
import { LABELMONTH, YEAR_MONTH } from '@/libs/filterDate'
import { ppn } from '@/constants/tax'
import { tableMonitor } from './config'
import '@core/scss/vue/libs/vue-select.scss'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VSelect,
    MonthlyPicker,
  },
  data() {
    return {
      IDR,
      ppn,
      fields: tableMonitor,
      visible: false,
      loading: false,
      loadDownload: false,
      percentDownload: 0,
      loadingSubmit: false,
      alertError,
      alertSuccess,
      required,
      YEAR_MONTH,

      idPartner: null,
      periode: null,
      listPartners: [],
      listAdminFee: [],
      items: [],
      indexSelected: null,
      money: {
        thousands: '.',
        prefix: 'Rp ',
        precision: 0,
        masked: false,
      },
      monthlabel: LABELMONTH,
      totalNominal: 0,
      listDisbursementId: [],
      paymentOptions: [
        { text: 'Pembayaran Awal', value: true },
        { text: 'Pembayaran Akhir', value: false },
      ],
      isEarlyPayment: null,
      notes: '',
      hideCosts: null,
      isTax: 0,
      totalTax: 0,
      totalAdminFee: 0,
      adminServicefee: 0,
      totalTalentSalary: 0,
      invoiceCode: null,
      talentSalaries: [],
    }
  },
  computed: {
    maxDatePicker() { return this.$moment().endOf('month') },
    ppnValue() {
      return Math.ceil((this.ppn / 100) * this.totalAdminFee)
    },
    totalAdditionalCost() {
      return this.items.reduce((a, b) => a + b.additional_cost, 0)
    },
  },
  mounted() {
    this.getDetailData()
  },
  methods: {
    getTalentSalary(id) {
      const talent = this.talentSalaries.find(t => t.talent_id === id)
      return talent ? talent.talent_salary : 0
    },
    async getDetailData() {
      try {
        const res = await Promise.all(['disbursement', 'detail'].map(path => komtimAxiosIns.get(`v1/invoices/${this.$route.params.id}/${path}`)))
        this.detailData = res[0].data.data
        const { data: { data } } = res[1]
        this.loadForm()
        this.isTax = data.is_tax
        this.totalNominal = data.total_nominal
        this.totalTax = data.total_tax
        this.totalAdminFee = data.total_admin_fee
        this.adminServicefee = data.admin_service
        this.totalTalentSalary = data.total_salary_talent
        this.invoiceCode = data.invoice_code
        this.talentSalaries = data.data.map(({ talent_id, talent_salary }) => ({ talent_id, talent_salary }))
      } catch (err) {
        this.alertError(err)
      } finally {
        this.loading = false
      }
    },
    handleInput(val) {
      this.items = []
      if (val) this.getListTalent()
    },
    setAdminFee(index) {
      this.indexSelected = index
    },
    setIndexAdminFee(val) {
      if (val.description && !val.id) this.items[this.indexSelected].admin_fee = parseInt(val.description, 10)
      else if (val.description && val.id) {
        this.items[this.indexSelected].admin_fee = parseInt(val.admin_fee, 10)
        this.items[this.indexSelected].admin_fee_id = val.id
      }
    },
    loadForm() {
      this.items = this.detailData.data
      this.periode = YEAR_MONTH(this.detailData.invoice_period)
      this.idPartner = this.detailData.partner_name
      this.isEarlyPayment = this.detailData.is_early_payment
      this.notes = this.detailData.notes
      this.hideCosts = this.detailData.hide_costs

      const filterComplete = this.detailData.data.filter(el => el.disbursement_status.toUpperCase() === 'FAILED')
      this.listDisbursementId = filterComplete.map(el => el.disbursement_id)
    },
    async disburseAll() {
      for (let i = 0; i < this.listDisbursementId.length; i += 1) {
        /* eslint-disable no-await-in-loop */
        await this.disbursement(this.listDisbursementId[i])
      }
    },
    async sendDisbursement(id) {
      this.loadingSubmit = true

      const url = `v1/invoices/${id}/disbursement`
      await komtimAxiosIns
        .put(url)
        .then(() => {
          const text = 'Disbursement berhasil'
          this.alertSuccess(text)

          this.getDetailData()
          this.loadingSubmit = false
        })
        .catch(error => {
          this.alertError(error)
        })
    },
    async disbursement(id) {
      this.loadingSubmit = true

      const url = `v1/invoices/${id}/disbursement`
      await komtimAxiosIns
        .put(url)
        .then(() => {
          const text = 'Disbursement berhasil'
          this.alertSuccess(text)

          this.loadingSubmit = false
          return new Date()
        })
        .catch(error => {
          this.alertError(error)
        })
    },
    async onDownloadInvoice() {
      try {
        this.loadDownload = true
        const res = await komtimAxiosIns.get(`v1/invoices/download?invoice_code=${this.invoiceCode}`, {
          responseType: 'blob',
          onDownloadProgress: progressEvent => {
            if (progressEvent.total) {
              this.percentDownload = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            }
          },
        })

        const blob = new Blob([res.data], { type: 'application/octet-stream' })
        const url = URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.href = url
        a.download = `invoice_active_${this.$route.params.id}_${this.detailData.invoice_period}.pdf`
        a.target = '_blank'
        a.click()

        a.remove()
        URL.revokeObjectURL(url)
      } catch (error) {
        this.$toast_error({ message: 'Gagal mengunduh invoice' })
      } finally {
        this.loadDownload = false
        this.percentDownload = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.status {
  padding: 1px 10px;
  text-transform: capitalize;
  border-radius: 5px;

  &-red {
    border: 1px solid #E31A1A;
    background: #FFEDED;
    color: #E31A1A;
  }
  &-yellow {
    border: 1px solid #FBA63C;
    background: #FFF2E2;
    color: #FBA63C;
  }
  &-green {
    border: 1px solid #34A770;
    background: #DCF3EB;
    color: #34A770;
  }
}
</style>
